import Typography from "@mui/material/Typography";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Grid from "@mui/material/Grid"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import WebIcon from '@mui/icons-material/Web';


import DataProjects from "../DataProjects"
import DataAbout from "../DataAbout";

import { Button, Backdrop } from "@mui/material";
import { useState } from "react";

const Home = (props) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    return (

        <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Grid container direction="row"
                justifyContent="center"
                alignItems="center" sx={{ height: '100%', width: "100%" }}>
                <Grid mt={10}>
                    <img className="home-img" src="https://i.imgur.com/LYCD1L5.jpg" />
                </Grid>
                <Grid ml={7}>
                    <div class="content">
                        <h2 class="text_shadows">Hello!</h2>
                    </div>
                    <h2 className="home-h2">
                        my name is Candace
                    </h2>

                    <h2 className="home-h2">
                        I'm a software engineer in Chattanooga TN
                    </h2>
                </Grid>
                <Grid container alignItems="baseline" justifyContent="center" mt={5} >
                    <a className="icons" href="https://github.com/candacepinero"><GitHubIcon fontSize="large" /></a>
                    <a className="icons" href="https://www.linkedin.com/in/candace-pinero/"><LinkedInIcon fontSize="large" /></a>
                </Grid>



                <Grid container alignItems="baseline" justifyContent="space-evenly"  >
                    <Button onClick={handleToggle}>View Skills</Button>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >

                        <div className="skills">
                            <img height="200" src="https://i.imgur.com/mhbeqVn.jpg" />

                            <img height="200" src="https://i.imgur.com/EsVmoFa.jpg" />
                            <img height="200" src="https://i.imgur.com/HzO8TFg.png" />
                            <img height="200" src="https://i.imgur.com/Mlxg2um.jpg" />
                            <img height="200" src="https://i.imgur.com/Py1rI0q.jpg" />
                            <img height="200" src="https://i.imgur.com/ckrgtsO.png" />
                            <img height="200" src="https://i.imgur.com/7ReQfQs.png" />
                        </div>

                    </Backdrop>
                </Grid>

            </Grid>
            <Grid width="100%" height="5rem" container alignItems="center" justifyContent="center" mt={10}>
                <h3 className="projects-text">Projects</h3>
            </Grid>

            {/* ================================Projects section ================================== */}
            {DataProjects.map((projects) => {
                const { name, live, git, image, description } = projects
                return (
                    <Grid mt={10} mb={2}>
                        <Card className="project-cards" sx={{ maxWidth: 350, bgcolor: "black", border: "1px #de09ed solid" }}>
                            <CardMedia
                                component="img"
                                alt="green iguana"
                                height="200"
                                image={image}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" color="blue">
                                    {name}
                                </Typography>
                                <Typography variant="body2" color="white">
                                    {description}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" href={git}><GitHubIcon /></Button>
                                <Button size="small" href={live}><WebIcon /> Live</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                )
            })}


            {/* =============================== About section ================================== */}
            <Grid>
                {DataAbout.map((about) => {
                    const { name, email, headshot, bio } = about
                    return (
                        <Grid container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Grid mt={15}>
                                <h3 className="about-text">About Me</h3>
                            </Grid>
                            <Grid container alignItems="baseline" justifyContent="center" mt={5}>
                                <img className='about-img' src={headshot} />
                            </Grid>
                            <Grid width={1000} mt={7} >
                                {/* <a className="icons" href={about.email}><EmailIcon fontSize="large"/></a> */}
                                <h2 className="home-h2" mb={5} color={"white"} variant='subtitle1'>{bio}</h2>

                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>


        </Grid>















    );
}

export default Home;