import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom"
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid'
const Header = (props) => {





    return (


        <Grid>
            <Toolbar>

                <Typography variant="h3" color={"black"} sx={{ flexGrow: 1 }} >
                    CP.
                </Typography>
                {/* <Stack direction="row" spacing={2} fontSize={30}  >
                    <Link to="/">Home</Link>
                    <Link to="/about"> About</Link>
                    

                </Stack> */}







            </Toolbar>

        </Grid>
    );
}

export default Header;