import DataAbout from "../DataAbout"
import { Typography } from '@mui/material';
import Grid from "@mui/material/Grid";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";


const About = (props) => {
  return (
   
    <Grid>
      {DataAbout.map((about) => {
        const { name, email, headshot, bio } = about
        return (
          <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid mt={10}>
              <Typography variant='h1' color={"white"}>{name}</Typography>
            </Grid>
            <Grid container alignItems="baseline" justifyContent="center"  mt={5}>
              <img className='about-img' src={headshot} />
            </Grid>
            <Grid width={800} mt={7}>
              {/* <a className="icons" href={about.email}><EmailIcon fontSize="large"/></a> */}
              <Typography mb={5} color={"white"} variant='subtitle1'>{bio}</Typography>
              <a  className="icons" href="https://github.com/candacepinero"><GitHubIcon fontSize="large" /></a>
              <a className="icons" href="https://www.linkedin.com/in/candace-pinero/"><LinkedInIcon fontSize="large" /></a>
            </Grid>
          </Grid>
        )
      })}
    </Grid>
   
  );

}

export default About;