
import "./App.css";

// IMPORT COMPONENTS
import Header from "./component/Header";

import { Route, Switch } from "react-router-dom";
// IMPORT PAGES
import Home from "./pages/ Home"
import About from "./pages/About";


function App() {
  // URL should have YOUR HEROKU URL for your backend, make sure you include the trailing slash
  const URL = "https://expressreact-lab.herokuapp.com/";

  return (
    <div className="App">
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
      </Switch>
      
    </div>
  );
}

export default App;