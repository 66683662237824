let DataProjects = [
    {
      name: "Flower Box",
      live: "https://master--lucent-gnome-a9bdf0.netlify.app/",
      git: "https://github.com/candacepinero/capstone-flowerbox",
      image: "https://camo.githubusercontent.com/e598ca820fa15de4f9032ddb76ecf84f5efe7cbf3fefa69eb475e931a7ae1d56/68747470733a2f2f692e696d6775722e636f6d2f514e58703778352e706e67",
      description: "Flower Box is an e-commerce storefront application. I created Flower Box using react and material-UI. "
    },
    {
      name: "Tails Of Joy",
      live: "https://tailsofjoy.herokuapp.com/",
      git: "https://github.com/candacepinero/tails_of_joy",
      image: "https://user-images.githubusercontent.com/103546768/178895818-4c1bfe5d-6f84-4ecb-b77b-a15528c46cfd.png",
      description: "Tails Of Joy is a full CRUD application where users can manage cards to display information about mythical creatures"
    },
    {
      name: "Mater Lingua",
      live: "https://materlingua.netlify.app/",
      git: "https://github.com/candacepinero/Project-3-Application-Frontend",
      image: "https://camo.githubusercontent.com/43e97ee8ddf4a6db6570e0b3a6e5bfa9bdea05d70065639654b81341fb49b600/68747470733a2f2f692e696d6775722e636f6d2f6534484f34634e2e706e67",
      description: "Mater Lingua is a language learning application that I helped create. I had the opportunity to work with some amazing developers on this project, and I felt very lucky to have been paired with them, it was a great experience.  "
    },
    {
      name: "Recipes Only",
      live: "https://recipesonly.herokuapp.com/recipes",
      git: "http://www.github.com/username/Recipes-Only",
      image: "https://i.imgur.com/3vroJDS.png",
      description: "Recipes Only is a full CRUD Moroccan recipe application. "
    },
    {
      name: "Find A Friend",
      live: "https://candacepinero.github.io/Find-a-friend/",
      git: "https://github.com/candacepinero/Find-a-friend",
      image: "https://i.imgur.com/tqsXrOt.png",
      description: "Find a friend is a cat image app that allows users to view random cat photos. I used the cat API in this project to get the images."
    }
  ]

export default DataProjects;